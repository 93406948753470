import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Not found</h1>
    <p>Sorry, we could not find the page you were looking for.</p>
  </Layout>
)

export default NotFoundPage
